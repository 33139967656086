import * as React from "react"
import "../styles/styles.css"
import "../resources/slkscr.ttf"

// markup
const Header = ({pageName}) => {
  return (
    <div id="header">
        <span style={{alignSelf: "flex-end"}}><p style={{color: "white", fontFamily: "slkscr", height: "100%", float: "left", width: "fit-content"}}>exnihilocreations.dev/</p><input id="urlInput" type="text" placeholder={pageName} style={{backgroundColor: "black", border: 0, color: "lawngreen", fontFamily: "slkscr", margin: "0px 0px 10px 0px", float: "right", outline: "none"}}></input></span>
    </div>
  );
}

export default Header